// override defalt minima variables 

// width of the content area
// can be set as "px" or "%"
$content-width:    1000px;
$on-palm:          800px;
$on-laptop:        1000px;
$on-medium:        1000px;
$on-large:         1200px;
$title-font-family: 'Exo 2', sans-serif;
$base-font-family: 'Roboto', sans-serif;